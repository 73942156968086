import TeamMember from './TeamMember';
import TeamInvite from './TeamInvite';
import {TeamMemberRole} from './TeamMember';
import {sortBy} from '../util/array';

export type ProductType =
    | 'TeamAdmin'
    | 'MediaValue'
    | 'Termatico'
    | 'AdvertisingCreative'
    | 'DataEntry';

export type Product = {
    product: ProductType;
    industryIds: string[] | null;
    status: TeamProductStatus;
    metadata?: {trialEnd?: string} | null;
};

export enum TeamProductStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    TRIALING = 'TRIALING'
}

export default class Team {
    id: string;
    name: string;
    status: string;
    members: Array<TeamMember>;
    invites: Array<TeamInvite>;
    products: Array<Product>;
    productMap: {[K in ProductType]?: Product};
    trialExpired: boolean;
    createdAt: string;
    updatedAt: string;

    constructor(input: Partial<Team>) {
        for (const key in input) {
            this[key] = input[key];
        }
        this.members = input.members || [];
        this.invites = input.invites || [];
        this.products = input.products || [];
        this.productMap = this.products.reduce((rr, ii) => {
            rr[ii.product] = ii;
            return rr;
        }, {});
    }

    /**
     * Helper to check whether you can edit (change role, remove) a member of a team
     *
     * This is useful when complying with the rule of always having one owner on a team
     */
    canEditMember(member: TeamMember) {
        return this.canEditMemberRole(member, 'OWNER') || this.canEditMemberRole(member, 'MEMBER');
    }

    canEditMemberRole(member: TeamMember, role: TeamMemberRole) {
        if (this.id !== member.teamId) return false;

        switch (role) {
            case 'OWNER': {
                const hasMultipleOwners =
                    this.members.filter((member) => member.role === 'OWNER').length > 1;
                return member.role === 'OWNER' && hasMultipleOwners;
            }
            case 'MEMBER':
                return member.role === 'MEMBER';
            default:
                return false;
        }
    }

    //
    // Products

    get hasNoPermissions() {
        return (
            !this.hasTeamAdmin &&
            !this.hasMediaValue &&
            !this.hasTermatico &&
            !this.hasAdvertisingCreative &&
            !this.hasDataEntry
        );
    }

    get isAdminOnlyTeam() {
        return (
            (this.hasTeamAdmin || this.hasDataEntry || this.hasTermatico) &&
            !this.hasAdvertisingCreative &&
            !this.hasMediaValue
        );
    }

    get hasTeamAdmin() {
        return 'TeamAdmin' in this.productMap;
    }

    get hasMediaValue() {
        return Boolean(
            this.productMap.MediaValue && (this.productMap.MediaValue.industryIds?.length ?? 0) > 0
        );
    }

    get hasTermatico() {
        return 'Termatico' in this.productMap;
    }

    get hasAdvertisingCreative() {
        return Boolean(
            this.productMap.AdvertisingCreative &&
                (this.productMap.AdvertisingCreative.industryIds?.length ?? 0) > 0
        );
    }

    get hasDataEntry() {
        return 'DataEntry' in this.productMap;
    }

    get trialEndDate() {
        const longestTrialEndDate = sortBy(
            this.products,
            (pp) => {
                if (pp?.metadata?.trialEnd) return new Date(pp.metadata.trialEnd).getTime();
                return '';
            },
            true
        )[0];

        return longestTrialEndDate?.metadata?.trialEnd;
    }

    mediaValueIsAllowedIndustry(industryId: string) {
        if (!this.productMap.MediaValue) return false;
        const industries = this.productMap.MediaValue.industryIds || [];
        return industries.length === 0 || industries.includes(industryId) || industryId === 'All';
    }
}
