import Team from './Team';
import {TeamMemberRole} from './TeamMember';

export default class ViewerTeam extends Team {
    role: TeamMemberRole;
    constructor(data: Partial<Team> & {role: TeamMemberRole}) {
        super(data);
        this.role = data.role;
    }
}
