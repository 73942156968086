//
// Creative properties

export const adclarityDisplayKeys = [
    'adclarity_advertiser_domain',
    'adclarity_advertiser_name',
    'adclarity_creative_landingpage_keywords',
    'adclarity_creative_landingpage_title',
    'adclarity_creative_landingpage_url',
    'adclarity_creative_video_title'
];
export const adclarityFbKeys = [
    'adclarity_fb_campaign_text',
    'adclarity_fb_campaign_text_links',
    'adclarity_fb_headline_link',
    'adclarity_fb_headline_text',
    'adclarity_fb_page_link',
    'adclarity_fb_page_name'
];
export function isAdclarityKey(key: string) {
    return [...adclarityDisplayKeys, ...adclarityFbKeys].includes(key);
}

export const admonKeys = [
    'admon_ref_ad_type',
    'admon_ref_industry',
    'admon_ref_category',
    'admon_ref_brand'
];
export function isAdmonKey(key: string) {
    return admonKeys.includes(key);
}

export const propertyKeys = ['property_name', 'property_address', 'property_url'];
export function isPropertyKey(key: string) {
    return propertyKeys.includes(key);
}

export const adContentKeys = ['ad_text_or_transcription', 'ad_transcription', 'ad_text_content'];
export function isAdContentKey(key: string) {
    return adContentKeys.includes(key);
}

// All properties except basic
export const creativeAttributesExceptBasicKeys = [
    ...adContentKeys,
    ...adclarityDisplayKeys,
    ...adclarityFbKeys,
    ...admonKeys,
    ...propertyKeys
];
export function isCreativeAttributeExceptBasicKey(key: string) {
    return creativeAttributesExceptBasicKeys.includes(key);
}

// These are not suitable to create rules, because they are either too broad or specific
export const basicAttributeKeys = [
    'creativeId',
    'source',
    'status',
    'firstAppeared',
    'lastAppeared',
    'createdAt',
    'updatedAt'
];

//
// Spots and tags

export const spotEntities = ['mediaType', 'publication', 'publisher', 'mediaOwner', 'region'];
export const spotKeys = ['date', ...spotEntities];
export function isSpotKey(key: string) {
    return spotKeys.includes(key);
}

export const tagKeys = ['brand', 'industry', 'category', 'product', 'campaign', 'adType'];
export function isTagKey(key: string) {
    return tagKeys.includes(key);
}

// We can search entities by these keys
export const searchEntityInputKeys = [...tagKeys, ...spotEntities];
export function isSearchEntityInputKey(key: string) {
    return searchEntityInputKeys.includes(key);
}

// Dates
export const datePickerKeys = ['date', 'firstAppeared', 'lastAppeared', 'createdAt', 'updatedAt'];
export function isDatePickerKey(key: string) {
    return datePickerKeys.includes(key);
}

/** Used to render attributes in human friendly text with colour */
export const filterNameColorMap = {
    adclarity_advertiser_domain: {name: 'Advertiser domain', color: 'blue'},
    adclarity_advertiser_name: {name: 'Advertiser name', color: 'blue'},
    adclarity_creative_landingpage_keywords: {name: 'Landing page keywords', color: 'blue'},
    adclarity_creative_landingpage_title: {name: 'Landing page title', color: 'blue'},
    adclarity_creative_landingpage_url: {name: 'Landing page URL', color: 'blue'},
    adclarity_creative_video_title: {name: 'Video title', color: 'blue'},

    adclarity_fb_campaign_text: {name: 'Campaign text', color: 'blue'},
    adclarity_fb_campaign_text_links: {name: 'Campaign text links', color: 'blue'},
    adclarity_fb_headline_link: {name: 'Headline link', color: 'blue'},
    adclarity_fb_headline_text: {name: 'Headline text', color: 'blue'},
    adclarity_fb_page_link: {name: 'Page link', color: 'blue'},
    adclarity_fb_page_name: {name: 'Page name', color: 'blue'},

    admon_ref_ad_type: {name: 'Admon ref ad type', color: 'green400'},
    admon_ref_industry: {name: 'Admon ref industry', color: 'green400'},
    admon_ref_category: {name: 'Admon ref category', color: 'green400'},
    admon_ref_brand: {name: 'Admon ref brand', color: 'green400'},

    property_name: {name: 'Property name', color: 'red'},
    property_address: {name: 'Property address', color: 'red'},
    property_url: {name: 'Property URL', color: 'red'},

    ad_text_or_transcription: {name: 'Text or audio', color: 'purple'},
    ad_text_content: {name: 'Text', color: 'purple'},
    ad_transcription: {name: 'Audio', color: 'purple'},

    creativeId: {name: 'Creative ID', color: 'purple'},
    source: {name: 'Source', color: 'purple'},
    status: {name: 'Status', color: 'purple'},

    brand: {name: 'Brand', color: 'purple'},
    industry: {name: 'Industry', color: 'orange'},
    category: {name: 'Category', color: 'yellow'},
    campaign: {name: 'Campaign', color: 'green'},
    adType: {name: 'Ad type', color: 'blue'},
    mediaOwner: {name: 'Media owner', color: 'blue'},
    mediaType: {name: 'Media type', color: 'pink'},
    region: {name: 'Region', color: ''},
    publication: {name: 'Publication', color: ''},
    publisher: {name: 'Publisher', color: 'blue'},

    product: {name: 'Product', color: 'cyan'},
    date: {name: 'Spot Date', color: ''},

    firstAppeared: {name: 'First appeared', color: 'purple'},
    lastAppeared: {name: 'Last appeared', color: 'purple'},
    createdAt: {name: 'Created', color: 'purple'},
    updatedAt: {name: 'Last updated', color: 'purple'}
};

//
// The functions below are used to determine which operators to use (`=`, `contains` etc) for filters.
// It will make more sense if you read this
// @link https://www.notion.so/bigdatr/How-text-filters-work-fcc99ad9873e40dfbd5a9f78da2cbc95

/** 'keywords' are non-sentence like strings. for example URLs and Admon refs  */
export function isKeywordKey(input: string) {
    return [
        'adclarity_advertiser_domain',
        'adclarity_creative_landingpage_url',
        'adclarity_fb_campaign_text_links',
        'adclarity_fb_headline_link',
        'adclarity_fb_page_link',
        'admon_ref_ad_type',
        'admon_ref_industry',
        'admon_ref_category',
        'admon_ref_brand',
        'property_url'
    ].includes(input);
}

/** 'textField' means these filters have sentences in their strings. */
export function isTextFieldKey(input: string) {
    return [
        'ad_text_or_transcription',
        'ad_text_content',
        'ad_transcription',
        'adclarity_advertiser_name',
        'adclarity_creative_landingpage_keywords',
        'adclarity_creative_landingpage_title',
        'adclarity_creative_video_title',
        'adclarity_fb_campaign_text',
        'adclarity_fb_headline_text',
        'adclarity_fb_page_name',
        'property_name',
        'property_address'
    ].includes(input);
}

export function isPublicationKey(input: string) {
    return ['mediaType', 'region', 'publication'].includes(input);
}
