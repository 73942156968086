import {safeLazyImport} from 'bigdatr-style';
import {BoringRoute, createRouterContext, LazyRoute, Parse} from 'trouty';
import {CreativeEditRouteArgs} from '~/pages/CreativeEditRoute';
import {CreativeTaggerArgs} from '~/pages/CreativeTaggerRoute';
import {OrderBy, Presets, Query, toQuery} from '~/filters/data/parseFilters';
import {MappingFilter, MappingRouteArgs, MappingType} from '~/mapping/MappingRoute';
import entityRoutes from '~/entity-editor/data/EntityRoutes';
import ruleRoutes from '~/rules/data/RuleRoutes';
import creativeDone from '~/pages/CreativeDone';
import AlertsPage from '~/pages/AlertsPage';

const MappingRoute = safeLazyImport(() => import('~/mapping/MappingRoute'));
const CreativeTaggerRoute = safeLazyImport(() => import('~/pages/CreativeTaggerRoute'));
const CreativeEditRoute = safeLazyImport(() => import('~/pages/CreativeEditRoute'));
const CreativeUploadRoute = safeLazyImport(() => import('~/pages/CreativeUploadRoute'));
const SettingsRoute = safeLazyImport(() => import('~/pages/SettingsRoute'));
const Releases = safeLazyImport(() => import('~/pages/Releases'));
const Changesets = safeLazyImport(() => import('~/pages/Changesets'));

const {routes, RoutesProvider, useRoutes} = createRouterContext({
    mapping: LazyRoute<MappingRouteArgs>({
        path: '/mapping/:type/:id?',
        component: MappingRoute,
        parse: {
            type: Parse.param.string(
                (x) => (x || 'adClarityAdvertiserIndustryMapping') as MappingType
            ),
            id: Parse.param.string((x) => x),
            q: Parse.query.JSON((x) => x as MappingFilter)
        }
    }),
    creativeTagger: LazyRoute<CreativeTaggerArgs>({
        path: '/creative-tagger',
        parse: {
            filters: Parse.query.JSON((x) => (x as Query) ?? toQuery(undefined, true)),
            presets: Parse.query.JSON((x) => x as Presets),
            refetchRequest: Parse.state((x) => Boolean(x)),
            view: Parse.query.string((x) => (x as CreativeTaggerArgs['view']) ?? 'list'),
            orderBy: Parse.query.JSON((x) => x as OrderBy),
            currentId: Parse.state((x) => (x ? String(x) : undefined))
        },
        component: CreativeTaggerRoute
    }),
    creativeEdit: LazyRoute<CreativeEditRouteArgs>({
        path: '/creative(-tagger)?/:id',
        parse: {
            id: Parse.param.string((x) => x as string),
            tab: Parse.query.string((x) => (x as CreativeEditRouteArgs['tab']) || 'Tags'),
            isCreativeAllocated: Parse.state((x) => x as boolean),
            filters: Parse.state((x) => x as Query),
            presets: Parse.state((x) => x as Presets)
        },
        component: CreativeEditRoute
    }),
    alerts: BoringRoute({
        path: '/alerts',
        component: AlertsPage
    }),
    creativeUpload: BoringRoute({path: '/creative-upload', component: CreativeUploadRoute}),
    creativeDone,
    settings: BoringRoute({
        path: '/settings',
        component: SettingsRoute
    }),
    changesets: LazyRoute<{
        id?: number;
        tab?: string;
    }>({
        path: '/changeset/:id?',
        component: Changesets,
        parse: {
            id: Parse.param.number((x) => x),
            tab: Parse.query.string((x) => x ?? 'Selections')
        }
    }),
    /** @deprecated use `changesets` */
    releases: LazyRoute<{
        id?: number;
        archived?: boolean;
        tab?: string;
        drafts?: boolean;
    }>({
        path: '/release/:id?',
        component: Releases,
        parse: {
            archived: Parse.query.boolean(Boolean),
            id: Parse.param.number((x) => x),
            tab: Parse.query.string((x) => x ?? 'Selections'),
            drafts: Parse.query.boolean(Boolean)
        }
    }),
    ...entityRoutes,
    ...ruleRoutes
});

export {routes, RoutesProvider, useRoutes};
