import Team from '../team/Team';
import TeamInvite from '../team/TeamInvite';
import TeamMember from '../team/TeamMember';
import ViewerTeam from '../team/ViewerTeam';
import {ClassSchema, createListEntitySchemas} from './BaseEntities';
import User from './User';
import Viewer from './Viewer';
import {ArraySchema, ObjectSchema, EntitySchema} from 'react-enty';
import Brand from '../brand/data/Brand';
import Industry from '../industry/data/Industry';
import Category from '../category/data/Category';
import Campaign from '../campaign/data/Campaign';
import Creative from '../media/Creative';
import MediaType from '../mediaType/data/MediaType';
import AdType from '../adType/data/AdType';
import Publication from '../publication/data/Publication';
import Region from '../region/data/Region';
import Product from '../product/data/Product';
import MediaOwner from '../mediaOwner/data/MediaOwner';
import Publisher from '../publisher/data/Publisher';

export const viewer = new EntitySchema('viewer', {});
export const user = new EntitySchema('user', {});
export const userList = new EntitySchema('userList', {
    id: (x: any) => x.id,
    shape: new ArraySchema(user)
});
export const team = new EntitySchema('team', {});
export const viewerTeam = new EntitySchema('viewerTeam', {});
export const teamList = new ArraySchema(team);
const teamListSchemas = createListEntitySchemas('rootTeamList', team);
export const rootTeamList = teamListSchemas[0];
export const rootTeamListAdd = teamListSchemas[1];
export const teamMember = new EntitySchema('teamMember', {
    id: ({userId, teamId}) => `${userId}${teamId}`
});
export const teamMemberList = new ArraySchema(teamMember);
export const teamInvite = new EntitySchema('teamInvite', {});
export const teamInviteList = new ArraySchema(teamInvite);

export const brand = new EntitySchema('brand', {id: (e) => e?.id || e?.name || 'NONE'});
export const brandList = new ArraySchema(brand);

export const industry = new EntitySchema('industry', {id: (e) => e?.id || e?.name || 'NONE'});
export const industryList = new ArraySchema(industry);

export const category = new EntitySchema('category', {id: (e) => e?.id || e?.name || 'NONE'});
export const categoryList = new ArraySchema(category);

export const campaign = new EntitySchema('campaign', {id: (e) => e?.id || e?.name || 'NONE'});
export const campaignList = new ArraySchema(campaign);

export const creative = new EntitySchema('creative', {});
export const creativeList = new ArraySchema(creative);

export const mediaOwner = new EntitySchema('mediaOwner', {id: (e) => e?.id || e?.name || 'NONE'});
export const mediaOwnerList = new ArraySchema(mediaOwner);

export const mediaType = new EntitySchema('mediaType', {id: (e) => e?.id || e?.name || 'NONE'});
export const mediaTypeList = new ArraySchema(mediaType);

export const adType = new EntitySchema('adType', {id: (e) => e?.id || e?.name || 'NONE'});
export const adTypeList = new ArraySchema(adType);

export const product = new EntitySchema('product', {id: (e) => e?.id || e?.name || 'NONE'});
export const productList = new ArraySchema(product);

export const publisher = new EntitySchema('publisher', {id: (e) => e?.id || e?.name || 'NONE'});
export const publisherList = new ArraySchema(publisher);

export const region = new EntitySchema('region', {id: (e) => e?.id || e?.name || 'NONE'});
export const regionList = new ArraySchema(region);

export const publication = new EntitySchema('publication', {id: (e) => e?.id || e?.name || 'NONE'});
export const publicationList = new ArraySchema(publication);

//
// Root Schemas
export const relationRoot = new ObjectSchema({
    industryList,
    brandList,
    categoryList
});

export const teamRoot = new ObjectSchema({
    team,
    teamList,
    teamInviteUser: teamInvite,
    teamCreateWithInvite: rootTeamListAdd,
    teamUpdate: team,
    teamUpdateUser: teamMember,
    teamAddUser: teamMember
});

export const userRoot = new ObjectSchema({
    user,
    userUpdate: user,
    userList,
    userUpdateProducts: user
});

// shapes
brand.shape = new ClassSchema(Brand, {});
industry.shape = new ClassSchema(Industry, {});
category.shape = new ClassSchema(Category, {});
campaign.shape = new ClassSchema(Campaign, {});
creative.shape = new ClassSchema(Creative, {});
mediaOwner.shape = new ClassSchema(MediaOwner, {});
mediaType.shape = new ClassSchema(MediaType, {});
adType.shape = new ClassSchema(AdType, {});
product.shape = new ClassSchema(Product, {});
publisher.shape = new ClassSchema(Publisher, {});
region.shape = new ClassSchema(Region, {});
publication.shape = new ClassSchema(Publication, {});

user.shape = new ClassSchema(User, {
    memberships: new ArraySchema(teamMember)
});

viewer.shape = new ClassSchema(Viewer, {
    user,
    teams: new ArraySchema(viewerTeam)
});

viewerTeam.shape = new ClassSchema(ViewerTeam, {
    members: teamMemberList,
    invites: teamInviteList
});
team.shape = new ClassSchema(Team, {
    members: teamMemberList,
    invites: teamInviteList
});
teamMember.shape = new ClassSchema(TeamMember, {user, team});
teamInvite.shape = new ClassSchema(TeamInvite, {team});
