//
//
// !!!!!!!!!!!!!!!!!!!!
// Anything you use from this file will require you to make `launchdarkly-react-client-sdk`
// package a singleton module in the webpack config of the package you want to use it
// example on how to do it:
// https://github.com/bigdatr/bigdatr-v2/blob/HEAD@%7B2023-11-30%7D/packages/bigdatr-client-main/webpack.config.js#L169-L170
// !!!!!!!!!!!!!!!!!!!!
//
//

import LaunchDarkly from './LaunchDarkly';
import Loader from '../../affordance/Loader';
import {
    useFlags,
    LDProvider,
    useLDClientError,
    useLDClient,
    ProviderConfig
} from 'launchdarkly-react-client-sdk';
import React, {createContext, useContext, useState} from 'react';
import {LDFlagSet} from 'launchdarkly-js-sdk-common';

// we add our own context below launch darkly's original context. this allows us to add custom logic
// like rerendering the app once we initialise the user with real viewer data
const FeatureFlagsContext = createContext<
    | {
          flags: LDFlagSet;
          ldClient: LaunchDarkly;
      }
    | undefined
>(undefined);

export function FeatureFlagsProvider(props: {
    children: React.ReactNode;
    providerProps?: Omit<ProviderConfig, 'clientSideID' | 'context'>;
}) {
    return (
        <LDProvider
            {...props.providerProps}
            /**
             * launch darkly will initialize an anonymous user by default if you don't provide a context,
             * but i'm doing this so its extra clear on what we're doing and want to achieve:
             * 1. init anonymous user
             * 2. render loader in app
             * 3. identify user in your IndexView
             * 4. trigger rerender so loader goes away
             *
             * @important you need to do step 2 and 3 by yourself in your implementation
             */
            context={{kind: 'user', anonymous: true}}
            clientSideID={process.env.LAUNCH_DARKLY_CLIENT_ID ?? ''}
        >
            <FeatureFlagsCandy children={props.children} />
        </LDProvider>
    );
}

// our usage of feature flags only makes sense once we have the viewer and launch darkly
// initialises with the viewer data.
//
// we tried to fetch the flags first, and only then render the app (we used LD's exported
// `asyncWithLDProvider` function), however that meant when we used default values AND targeting
// of flags based on viewer data (eg is the viewer a staff member), you get a flicker of the
// flag having the default value, and then it would quickly switch to the intended value set by
// targeting settings
//
// because of that we're adding this sweet candy component that works around the bitter issues we had.
// it does a few things:
// 1. renders a `<Loader />` until the anonymous user has initialised
// 2. instantiates a `LaunchDarkly` analytics object which allows us to use it like any other 3rd party provider we use
//   2a. when launch darkly initialises a user with viewer data (this happens when you call `analtyics.identify`), it
//      forces a rerender so the whole app has the correct feature flag data (even when custom targeting is enabled on
//      the launch darkly website for flags, eg Segments)
// 3. throws errors if they occur
// 4. returns the flags to our custom context, which can be accessed by the `useSafeFeatureFlags` hook
function FeatureFlagsCandy(props: {children: React.ReactNode}) {
    const ldClientRaw = useLDClient();
    const flags = useFlags();
    const ldClientError = useLDClientError();
    const [, forceRerender] = useState(Date.now());

    // the launch darkly client won't initialize until we set some context (it will be undefined).
    // we're passing an anonymous context at first (up in the LDProvider), however initializing is
    // async, and there is a brief period until we actually get the launch darkly client
    if (!ldClientRaw) return <Loader />;

    // abort
    if (ldClientError) throw ldClientError;

    // allows us to treat launch darkly like any other 3rd party provider. we force a rerender once
    // an initialize call is made, because launch darkly's react implementation doesn't do it
    const ldClient = new LaunchDarkly(ldClientRaw, () => forceRerender(Date.now()));

    return (
        <FeatureFlagsContext.Provider value={{flags: flags, ldClient}}>
            {props.children}
        </FeatureFlagsContext.Provider>
    );
}

/** Ensures you get an initialised client (can be with an anonymous user). You need to ensure you
 * render the app **only** when the context is using viewer data (eg is not anonymous).
 *
 * To do this, render a `Loader` if `ldClient.isAnonymousContext()` is true (look at `IndexView.tsx` in any project).
 *
 * @important You will also need to call `ldClient.identify` to break the loading loop.
 **/
export function useLaunchDarklyClient() {
    const data = useContext(FeatureFlagsContext);
    if (!data) throw new Error('Launch Darkly client has not initialized yet');
    return data.ldClient;
}

/** Ensures you get accurate feature flags based on viewer targeting
 * In your project implementation, you should create your own hook which uses this hook, and just
 * casts the flags to the feature flags you actually use in that project
 *
 * If you need to write a unit test for a component which consumes this hook, you will need to mock
 * the package's implementation of this hook. For example, if the test is in client-data-entry,
 * you write a mock file for `useFeatureFlags.ts` hook which is inside data-entry, and then call
 * `jest.mock('path-to-mocked-file')`
 */
export function useSafeFeatureFlags(): LDFlagSet {
    const data = useContext(FeatureFlagsContext);
    if (!data) throw new Error('Launch Darkly client has not initialized yet');
    if (!data.flags) throw new Error("Flags haven't initialised yet");
    return data.flags;
}
